const footer = document.querySelector("footer");
const stickyButtons = document.querySelector("[data-sticky-buttons]");
const adjustCtasButtons = () => {
  if (!stickyButtons) {
    return;
  }
  const marginBottom = stickyButtons.offsetHeight;
  footer.style.marginBottom = `${marginBottom}px`;
};
if (stickyButtons) {
  document.addEventListener("scroll", () => {
    adjustCtasButtons();
  });
  document.addEventListener("on:toggle", () => {
    adjustCtasButtons();
  });
}
